import { Column as ColumnType } from "react-table";
import React, { useMemo } from "react";
import { useTrans } from "system/translations/hooks";
import { FormConfigType } from "../formBuilder/types";
import DotsMenu from "components/atoms/DotsMenu";
import { useDefaultFieldsConfig } from "modules/formBuilder/hooks";
import { useMonitoringModuleData } from "modules/pendingTransactionsMonitoring/hooks";
import { TableColumnType } from "modules/table/types";

export const useTableColumns = (): TableColumnType[] => {
  const { _t } = useTrans();
  const columns = useMemo(
    (): TableColumnType[] => [
      {
        Header: _t("id"),
        accessor: "id",
        id: "id1",
        disableSortBy: true,
        width: 60,
        mobileVisible: true,
      },
      {
        Header: _t("payment_gateway"),
        accessor: "paymentGatewayCode",
        id: "paymentGatewayCode",
        disableSortBy: true,
        width: 230,
        mobileVisible: true,
        isMobileTitle: true
      },
      {
        Header: _t("deposits_percentage"),
        accessor: "depositsPercentage",
        id: "depositsPercentage",
        disableSortBy: true,
        minWidth: 190,
        mobileVisible: true,
      },
      {
        Header: _t("time_range_start"),
        accessor: "checkDepositsOlderThan",
        id: "checkDepositsOlderThan",
        disableSortBy: true,
        minWidth: 190,
        mobileVisible: true,
      },
      {
        Header: _t("check_deposits_to"),
        accessor: "checkDepositsTo",
        id: "checkDepositsTo",
        disableSortBy: true,
        minWidth: 190,
        mobileVisible: true,
      },
      {
        Header: _t("deposits_checking_enabled"),
        accessor: "depositsCheckingEnabled",
        id: "depositsCheckingEnabled",
        disableSortBy: true,
        minWidth: 220,
        mobileVisible: true,
        Cell: (props: any) => {
          return <>{`${props.row.original.depositsCheckingEnabled}`}</>;
        },
      },
      {
        Header: _t("check_withdrawals_older_than"),
        accessor: "checkWithdrawalsOlderThan",
        id: "checkWithdrawalsOlderThan",
        disableSortBy: true,
        minWidth: 190,
        mobileVisible: true,
      },
      {
        Header: _t("check_withdrawals_to"),
        accessor: "checkWithdrawalsTo",
        id: "checkWithdrawalsTo",
        disableSortBy: true,
        minWidth: 190,
        mobileVisible: true,
      },
      {
        Header: _t("time_range_end"),
        accessor: "withdrawalsPercentage",
        id: "withdrawalsPercentage",
        disableSortBy: true,
        minWidth: 190,
        mobileVisible: true,
      },
      {
        Header: _t("withdrawals_checking_enabled"),
        accessor: "withdrawalsCheckingEnabled",
        id: "withdrawalsCheckingEnabled",
        disableSortBy: true,
        minWidth: 220,
        mobileVisible: true,
        Cell: (props: any) => {
          return <>{`${props.row.original.withdrawalsCheckingEnabled}`}</>;
        },
      },
      {
        Header: _t("created_date"),
        accessor: "createdDate",
        id: "createdDate",
        disableSortBy: true,
        minWidth: 230,
        mobileVisible: true,
      },
      {
        Header: _t("updated_date"),
        accessor: "updatedDate",
        id: "updatedDate",
        disableSortBy: true,
        minWidth: 230,
        mobileVisible: true,
      },
      {
        maxWidth: 40,
        Header: "",
        id: "UpdateDeleteMenu",
        mobileVisible: true,
        Cell: (props: any) => {
          return (
            <DotsMenu
              clickParams={{
                id: props?.row?.original?.id,
                allData: props?.row?.original,
              }}
              items={props?.actionMenuItems}
            />
          );
        },
      },
    ],
    []
  );

  return columns;
};

export const useSearchFormConfig = (): FormConfigType => {
  const fields = useDefaultFieldsConfig();
  const formConfig = useMemo((): FormConfigType => {
    return [
      fields.filters.filterValue({
        componentProps: {
          showSearch: true,
          type: "submit",
        },
        columnParams: {
          md: 6,
          lg: 6,
        },
      }),
    ];
  }, []);
  return formConfig;
};

export const useEditConfig = (code: string): FormConfigType => {
  const fields = useDefaultFieldsConfig();
  const { _t } = useTrans()
  const formConfig = useMemo((): FormConfigType => {
    return [
      fields.formFields.paymentGatewayCode({
        componentProps: {
          items: [{label: code, value: code}],
          light: false,
          disabled: true,
        },
      }),
      fields.formFields.depositsPercentage(),
      fields.formFields.checkDepositsOlderThan({
        componentProps: {
          labelText: _t("time_range_start")
        }
      }),
      fields.formFields.checkDepositsTo(),
      fields.formFields.withdrawalsPercentage({
        componentProps: {
          labelText: _t("time_range_end")
        }
      }),
      fields.formFields.checkWithdrawalsOlderThan(),
      fields.formFields.checkWithdrawalsTo(),
      fields.formFields.depositsCheckingEnabled({
        columnParams: {
          lg: 8,
          md: 8,
        },
        emptySpace: {
          sm: { span: 16 },
          lg: { span: 16 },
          md: { span: 16 },
        },
      }),
      fields.formFields.withdrawalsCheckingEnabled({
        columnParams: {
          lg: 8,
          md: 8,
        },
      }),
      fields.general.divider(),
      fields.general.saveBtn(),
    ];
  }, [code]);
  return formConfig;
};
export const useCreateConfig = (): FormConfigType => {
  const fields = useDefaultFieldsConfig();
  const { _t } = useTrans()
  const { gatewaysOptions } = useMonitoringModuleData();  
  const formConfig = useMemo((): FormConfigType => {
    return [
      fields.formFields.paymentGatewayCode({
        componentProps: {
          items: gatewaysOptions,
          light: false,
        },
      }),
      fields.formFields.depositsPercentage(),
      fields.formFields.checkDepositsOlderThan({
        componentProps: {
          labelText: _t("time_range_start")
        }
      }),
      fields.formFields.checkDepositsTo(),
      fields.formFields.withdrawalsPercentage({
        componentProps: {
          labelText: _t("time_range_end")
        }
      }),
      fields.formFields.checkWithdrawalsOlderThan(),
      fields.formFields.checkWithdrawalsTo(),
      fields.formFields.depositsCheckingEnabled({
        columnParams: {
          lg: 8,
          md: 8,
        },
        emptySpace: {
          sm: { span: 16 },
          lg: { span: 16 },
          md: { span: 16 },
        },
      }),
      fields.formFields.withdrawalsCheckingEnabled({
        columnParams: {
          lg: 8,
          md: 8,
        },
      }),
      fields.general.divider(),
      fields.general.saveBtn(),
    ];
  }, [gatewaysOptions]);
  return formConfig;
};
